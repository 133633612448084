<template>
    <span>
        <span class="row">
            <span class="stats">
                <span class="stat tests" :title="totalTestsTitle"
                    ><span class="word">{{ tests.overall + " " }} </span>
                    <font-awesome-icon icon="fa-solid fa-flask " size="sm"
                /></span>
                <span class="stat success" :title="passedTestsTitle"
                    ><span class="word">{{ tests.passes + " " }} </span>
                    <font-awesome-icon
                        icon="fa-solid fa-circle-check"
                        size="sm"
                    />
                </span>
                <span class="stat failure" :title="failedTestsTitle"
                    ><span class="word">{{ tests.failures + " " }} </span>
                    <font-awesome-icon
                        icon="fa-solid fa-circle-xmark"
                        size="sm"
                    />
                </span>
                <span class="stat skipped" :title="skippedTestsTitle"
                    ><span class="word">{{ tests.skipped + " " }} </span>
                    <font-awesome-icon
                        icon="fa-solid fa-forward-step"
                        size="sm"
                    />
                </span>
            </span>
            <span class="run-time" :title="`Category ran on ${runDate}`">
                {{ runDate }}
            </span>
        </span>
    </span>
</template>
<script>
export default {
    name: "CategoryStats",
    props: {
        tests: undefined,
        runDate: undefined,
    },
    computed: {
        totalTestsTitle() {
            return `total tests in run ${this.tests.overall}`;
        },
        passedTestsTitle() {
            return `${this.tests.passes} tests passed`;
        },
        failedTestsTitle() {
            return `${this.tests.failures} tests failed`;
        },
        skippedTestsTitle() {
            return `${this.tests.skipped} tests skipped`;
        },
    },
};
</script>

<style>
.stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.stat {
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    max-width: 100px;
    height: 45px;
    font-size: 1rem;
}
.tests {
    background: rgb(187, 187, 187);
}
.success {
    background: #36b37e;
}
.failure {
    background: rgb(218, 60, 60);
}
.skipped {
    background: #81a4df;
}
.run-time {
    font-size: small;
    font-style: italic;
    text-align: right;
    padding-top: 12px;
    padding-right: 15px !important;
}

@media (max-width: 1225px) {
    .word {
        display: none;
    }
}
</style>
