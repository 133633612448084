import { createAuth0 } from "@auth0/auth0-vue";

export const auth0 = createAuth0({
    domain: "dev-4yzr8kqk4syega8t.us.auth0.com",
    clientId: "8KOgTDRxLhgCyz3YibLtLkDMRCCCZ5zv",
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://devteamhelper.com",
    },
    useRefreshTokens: true,
    theme: { logo: "https://dash.dayshapedev.app/image.png" },
});
