<template>
    <div class="container pr-widget">
        <span v-if="!prsLoaded">
            <div class="">
                <div class="loading blink_me">
                    <font-awesome-icon
                        icon="fa-solid fa-code-pull-request"
                        size="2xl"
                    />
                    <p>loading pr data</p>
                </div>
            </div>
        </span>
        <span v-else>
            <table class="table table-hover pr-table">
                <caption>
                    {{
                        getCaption
                    }}
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        title="Click to refresh PR data"
                        @click="loadPRs"
                    >
                        <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
                    </button>
                </caption>
                <thead>
                    <tr>
                        <th scope="col">
                            <font-awesome-icon
                                icon="fa-solid fa-code-pull-request"
                                style="color: #4c6794"
                            />
                        </th>
                        <th
                            scope="col"
                            style="text-align: left; padding-left: 3px"
                        >
                            Title
                        </th>
                        <th
                            v-for="(teamMember, columnIndex) in team"
                            :key="columnIndex"
                            scope="col"
                        >
                            <AvatarIcon :team-member="teamMember" />
                        </th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <PullRequestRow
                        v-for="(pr, prIndex) in prs"
                        :key="prIndex"
                        :pr="pr"
                        :team="team"
                    />
                    <tr class="totals-row">
                        <td />
                        <td style="text-align: right">Reviewing Count</td>
                        <td
                            v-for="(teamMember, columnIndex) in team"
                            :key="columnIndex"
                        >
                            <ReviewingCount
                                :team-member="teamMember"
                                :prs="prs"
                            />
                        </td>
                        <td />
                        <td />
                    </tr>
                </tbody>
            </table>
        </span>
    </div>
</template>
<script>
import ReviewingCount from "./ReviewingCount.vue";
import AvatarIcon from "../AvatarIcon.vue";
import PullRequestRow from "./PullRequestRow.vue";
import PullRequestService from "../../services/PullRequestService";

export default {
    name: "PRs",
    components: { ReviewingCount, AvatarIcon, PullRequestRow },
    props: {
        team: {},
    },
    data() {
        return {
            prs: [],
            prsLoaded: false,
            prDataLoadedDateTime: new Date(),
            now: null,
            moose: "moose",
        };
    },
    computed: {
        howManyMinutesAgo() {
            if (this.prDataLoadedDateTime) {
                let diff = Math.abs(this.now - this.prDataLoadedDateTime);
                let minutes = Math.floor(diff / 1000 / 60);
                return minutes;
            }
            return 0;
        },
        getCaption() {
            if (this.howManyMinutesAgo > 1) {
                return `Open PR data (loaded ${this.howManyMinutesAgo} minutes ago)`;
            } else if (this.howManyMinutesAgo > 0) {
                return `Open PR data (loaded ${this.howManyMinutesAgo} minute ago)`;
            }
            return `Open PR data (loaded seconds ago)`;
        },
    },
    watch: {
        team(newTeam) {
            if (newTeam?.length > 0) {
                this.loadPRs();
            }
        },
    },
    mounted() {
        this.prDataLoadedDateTime = new Date();
        if (this.team.length > 0) {
            this.loadPRs();
            setInterval(() => this.loadPRs(), 600000);
        }
    },
    methods: {
        loadPRs() {
            this.prsLoaded = false;
            this.prs = [];
            this.team.forEach((t) => {
                PullRequestService.getPullRequests(t.bitbucketUuid)
                    .then((res) => {
                        this.prs = this.prs.concat(res.data);
                        this.prDataLoadedDateTime = new Date();
                        setInterval(() => (this.now = new Date()));
                        this.prsLoaded = true;
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.error(error);
                    });
            });
        },
        getOwner(pr) {
            if (pr) {
                const owner = this.team.filter(
                    (tm) => tm.bitbucketUuid === pr.authorUuid
                );
                if (owner.length > 0) {
                    return owner[0];
                } else {
                    return undefined;
                }
            }
        },
    },
};
</script>

<style>
.loading {
    padding: 100px;
}
.totals-row {
    border-bottom-style: none;
}
.blink_me {
    animation: blinker 3s linear infinite;
}

.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    /* Specify a stack order in case you're using a different order for other elements */
    z-index: 9;
    height: 100%;
    width: 100%;
    max-width: 1320px;
    top: 0;
    left: 0;
}

@keyframes blinker {
    60% {
        opacity: 0;
    }
}

.pr-widget {
    position: relative;
    height: 100%;
}
</style>
