import { auth0 } from "../auth0.js";
import RequestsService from "./RequestsService.js";

async function getUser() {
    const user = await auth0.user;
    return user;
}

async function getUserNiceName() {
    const user = await getUser();
    var email = user.value.email;
    var enrichedUser = await RequestsService.get(`user/${email}`);
    return enrichedUser.data.displayName;
}

export default {
    getUser: getUser,
    getUserNiceName: getUserNiceName,
};
