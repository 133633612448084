import axios from "axios";
import { auth0 } from "../auth0.js";

async function getAccessToken() {
    const accessToken = await auth0.getAccessTokenSilently();
    return accessToken;
}

async function get(url) {
    const token = await getAccessToken();

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    return axios.get(`${process.env.VUE_APP_API}/${url}`, config);
}

export default {
    get: get,
};
