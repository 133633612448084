import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import HomePage from "../views/Home.vue";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomePage,
            beforeEnter: authGuard,
        },
    ],
});

export default router;
