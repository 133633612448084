<template>
    <div class="container">
        <span
            v-if="prs && teamMember"
            :title="`${teamMember.displayName} is the reviewer on ${reviewingCount} PRs`"
        >
            {{ reviewingCount }}
        </span>
        <span v-else> - </span>
    </div>
</template>
<script>
export default {
    name: "ReviewingCount",
    props: {
        prs: undefined,
        teamMember: undefined,
    },
    data() {
        return {
            reviewingCount: 0,
        };
    },
    watch: {
        prs(newPrs) {
            if (newPrs.length > 0) {
                this.setReviewingCount();
            }
        },
    },
    mounted() {
        this.setReviewingCount();
    },
    methods: {
        setReviewingCount() {
            if (this.prs.length > 0) {
                let count = 0;
                this.prs.forEach((pr) => {
                    count += pr.reviewers.filter(
                        (r) => r.uuid === this.teamMember.bitbucketUuid
                    ).length;
                });
                this.reviewingCount = count;
            } else {
                this.reviewingCount = 0;
            }
        },
    },
};
</script>

<style>
.green-count {
    color: #36b37e;
}
.amber-count {
    color: #ffab00;
}
.red-count {
    color: #de350b;
}
.no-count {
    color: #b5b7ba;
}
</style>
