<template>
    <div
        v-if="specs.length > 0"
        style="padding-bottom: 40px; padding-right: 80px"
    >
        <div
            class="toast-container position-fixed bottom-0 end-0 p-3 top-50 start-50 translate-middle"
        >
            <div
                id="liveToast"
                class="toast"
                :class="{ show: showToast }"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                aria-f
            >
                <div class="toast-header">
                    <img
                        src="/hello-there.png"
                        alt="Ewan McGreggor as Obi Wan"
                        class="avatar"
                    />
                    <strong class="me-auto" style="padding-left: 10px">
                        Hello there!</strong
                    >
                    <button
                        type="button"
                        class="btn-close me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                        @click="closeToast"
                    ></button>
                </div>
                <div class="toast-body">
                    🧶 run command copied to your clipboard!
                </div>
            </div>
        </div>
        <button
            id="liveToastBtn"
            type="button"
            class="btn btn-outline-secondary btn-sm"
            @click="copyCode"
        >
            <span class="code"
                ><font-awesome-icon icon="fa-solid fa-copy" /> Click to copy a
                run command of the above specs to your clipboard</span
            >
        </button>
    </div>
</template>
<script>
import { useClipboard } from "@vueuse/core";
const { copy } = useClipboard();

export default {
    name: "ExecutionScript",
    components: {},
    props: {
        specs: {},
        cypressVersion: undefined,
    },
    data() {
        return {
            showToast: false,
            interval: undefined,
        };
    },
    computed: {
        getScriptText() {
            if (this.cypressVersion) {
                return (
                    "yarn cypress run --browser chrome --spec '" +
                    this.specs.map((s) => s.specFileName).join(",") +
                    "'"
                );
            }
            return (
                "npx playwright test " +
                this.specs.map((s) => s.specFileName).join(" ")
            );
        },
    },
    methods: {
        copyCode() {
            this.showToast = true;
            copy(this.getScriptText);
            this.interval = setTimeout(() => {
                this.showToast = false;
            }, 7000);
        },
        closeToast() {
            this.showToast = false;
            clearTimeout(this.interval);
            this.interval = undefined;
        },
    },
};
</script>

<style>
.code-box {
    max-width: 90%;
}
.code {
    text-align: left !important;
    font-size: small;
}

.avatar {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}
</style>
