<template>
    <div class="col">
        <FilterModal
            :specs="run.specs"
            :filter-options="filterOptions"
            title="Click to filter by team"
            @filter="filterChanged"
        ></FilterModal>
        <div class="speccard">
            <div class="filter-msg">{{ filteredTo }}</div>
            <div v-if="specsToPresent.length > 0">
                <SpecFileResult
                    v-for="(spec, specIndex) in specsToPresent"
                    :key="specIndex"
                    :spec="spec"
                    :filter-to-team="filterToTeam"
                ></SpecFileResult>
                <ExecutionScript
                    v-if="specsToPresent.length > 0"
                    :specs="specsToPresent"
                    :cypress-version="run.cypressVersion"
                ></ExecutionScript>
            </div>
            <div v-else class="no-specs">
                <span
                    >There are no failing specs to display for the given run and
                    applied filters!
                    <p>
                        That's... That's good, right?! Can I has 🌮 now?
                    </p></span
                >
            </div>
        </div>
    </div>
</template>
<script>
import ExecutionScript from "./ExecutionScript.vue";
import SpecFileResult from "./SpecFileResult.vue";
import FilterModal from "./FilterModal.vue";

export default {
    name: "SpecsCard",
    components: { SpecFileResult, ExecutionScript, FilterModal },
    props: {
        run: {},
    },
    data() {
        return {
            filterToTeam: false,
            teamsToFilterTo: [],
            specsToPresent: [],
        };
    },
    computed: {
        pass() {
            return this.run.tests.overall === this.run.tests.passes;
        },
        filteredTo() {
            if (this.teamsToFilterTo.length > 0) {
                return (
                    "Currently filtering to " +
                    this.teamsToFilterTo.join(", ") +
                    " team specs"
                );
            } else {
                return "Displaying all teams";
            }
        },
        filterOptions() {
            if (this.run?.specs.some((s) => s.failedCount > 0)) {
                return [
                    ...new Set(
                        this.run.specs
                            .map((s) =>
                                [...new Set(s.tests.map((t) => t.team))].flat()
                            )
                            .flat()
                    ),
                ];
            }
            return [];
        },
    },
    mounted() {
        this.specsToPresent = this.run.specs;
    },
    methods: {
        toggleFilter() {
            this.filterToTeam = !this.filterToTeam;
        },
        updateFilter(teamName) {
            var teamIndex = this.teamsToFilterTo.indexOf(teamName);
            if (teamIndex >= 0) {
                this.teamsToFilterTo.splice(teamIndex, 1);
            } else {
                this.teamsToFilterTo.push(teamName);
            }
            this.setSpecs();
        },
        filterChanged(specsToDisplay, filteredToTeams) {
            this.specsToPresent = specsToDisplay;
            this.teamsToFilterTo = filteredToTeams;
        },
    },
};
</script>

<style>
.speccard {
    padding-left: 80px !important;
}

.filter-msg {
    padding-top: 30px;
    text-align: left;
    font-style: italic;
    color: grey;
}

.no-specs {
    font-size: large;
    padding: 100px;
    padding-right: 180px;
    color: grey;
    font-style: italic;
}
</style>
