<template>
    <div class="container">
        <span v-if="!testsLoaded">
            <div class="">
                <div class="loading blink_me">
                    <font-awesome-icon
                        icon="fa-solid fa-flask-vial"
                        size="2xl"
                    />
                    <p>loading test data</p>
                </div>
            </div>
        </span>
        <span v-else>
            <CategoryCard
                v-for="(run, runIndex) in runs"
                :key="runIndex"
                :run="run"
                :index="runIndex"
            ></CategoryCard>
            <div class="caption">
                {{ getCaption }}
                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    title="Click to referesh currents data"
                    @click="loadTests"
                >
                    <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
                </button>
            </div>
        </span>
    </div>
</template>
<script>
import TestService from "@/services/TestService";
import CategoryCard from "./CategoryCard.vue";

export default {
    name: "TestRuns",
    components: { CategoryCard },
    props: {
        team: [],
    },
    data() {
        return {
            runs: [],
            testsLoaded: false,
            testDataLoadedTime: null,
            now: null,
        };
    },
    computed: {
        howManyMinutesAgo() {
            if (this.testDataLoadedTime) {
                let diff = Math.abs(this.now - this.testDataLoadedTime);
                let minutes = Math.floor(diff / 1000 / 60);
                return minutes;
            }
            return 0;
        },
        getCaption() {
            if (this.howManyMinutesAgo > 1) {
                return `Currents data (loaded ${this.howManyMinutesAgo} minutes ago)`;
            } else if (this.howManyMinutesAgo > 0) {
                return `Currents data (loaded ${this.howManyMinutesAgo} minute ago)`;
            }
            return `Currents data (loaded seconds ago)`;
        },
    },
    mounted() {
        this.loadTests();
    },
    methods: {
        loadTests() {
            this.testsLoaded = false;
            this.runs = [];
            TestService.getTests()
                .then((res) => {
                    this.runs = res.data;
                    this.testDataLoadedTime = new Date();
                    setInterval(() => (this.now = new Date()));
                    this.testsLoaded = true;
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });
        },
    },
};
</script>

<style>
.loading {
    padding: 100px;
}
.totals-row {
    border-bottom-style: none;
}
.blink_me {
    animation: blinker 3s linear infinite;
}

.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    /* Specify a stack order in case you're using a different order for other elements */
    z-index: 9;
    height: 100%;
    width: 100%;
    max-width: 1320px;
    top: 0;
    left: 0;
}

@keyframes blinker {
    60% {
        opacity: 0;
    }
}

.pr-widget {
    position: relative;
    height: 100%;
}

.caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--bs-secondary-color);
    text-align: left;
}
</style>
