<template>
    <div class="filter-div col">
        <div class="dropdown">
            <div class="filter-button">
                <button
                    id="dropdownMenuButton"
                    class="btn btn-outline-secondary btn-sm"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <font-awesome-icon icon="fa-solid fa-filter" />
                </button>
                <div
                    class="dropdown-menu filter-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <form class="px-4 py-3">
                        <span class="dropdown-header filter-team-title"
                            >Filter To Specific Teams</span
                        >
                        <div
                            v-for="(option, index) in filterOptions"
                            :key="index"
                            class="form-check filter-menu"
                        >
                            <label :for="option">{{
                                option === "" ? "Unknown" : option
                            }}</label>
                            <span class="checkbox-box"
                                ><input
                                    :id="option"
                                    type="checkbox"
                                    :checked="isChecked(option)"
                                    @click="updateFilter(option)"
                            /></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "FilterModal",
    components: {},
    props: {
        specs: {},
        filterOptions: {},
    },
    emits: {
        filter(filteredSpecs, fitlertingBy) {
            if (filteredSpecs && fitlertingBy) {
                return true;
            }
            return false;
        },
    },
    data() {
        return {
            teamsToFilterTo: [],
            specsToPresent: [],
        };
    },
    mounted() {
        this.teamsToFilterTo = [];
        this.specsToPresent = [];
    },
    methods: {
        isChecked(teamName) {
            var teamIndex = this.teamsToFilterTo.indexOf(teamName);
            return teamIndex >= 0;
        },
        clearFilter() {
            this.teamsToFilterTo = [];
            this.filterSpecs();
        },
        updateFilter(teamName) {
            var teamIndex = this.teamsToFilterTo.indexOf(teamName);
            if (teamIndex >= 0) {
                this.teamsToFilterTo.splice(teamIndex, 1);
            } else {
                this.teamsToFilterTo.push(teamName);
            }
            this.filterSpecs();
        },
        filterSpecs() {
            if (this.teamsToFilterTo.length > 0) {
                this.specsToPresent = this.specs.filter((spec) => {
                    let totalTeamTests = spec.tests.filter((test) => {
                        return this.teamsToFilterTo.indexOf(test.team) >= 0;
                    });
                    return totalTeamTests.length > 0;
                });
            } else {
                this.specsToPresent = this.specs;
            }
            this.$emit("filter", this.specsToPresent, this.teamsToFilterTo);
        },
    },
};
</script>
<style>
.filter-div {
    padding-left: 25px;
    padding-top: 25px;
    position: absolute;
    max-width: 100%;
}

.filter-button {
    position: absolute;
}

.speccard {
    padding-left: 80px !important;
}

.filter-menu {
    width: 270px;
    font-size: small;
}

.filter-team-title {
    font-size: medium;
}

.checkbox-box {
    margin-top: 10px !important;
    padding-top: 10px !important;
    padding-left: 5px !important;
}

.clear {
    margin-right: 0px;
    margin-left: 150px;
}
</style>
