<template>
    <span v-if="state === 'passed'" title="passed">
        <font-awesome-icon
            icon="fa-solid fa-circle-check "
            size="sm"
            style="color: #36b37e"
        />
    </span>
    <span v-else-if="state === 'failed'" title="failed">
        <font-awesome-icon
            icon="fa-solid fa-circle-xmark "
            size="sm"
            style="color: #e65b43"
        />
    </span>
    <span v-else-if="state === 'skipped'" title="skipped">
        <font-awesome-icon
            icon="fa-solid fa-forward-step "
            size="sm"
            style="color: #81a4df"
        />
    </span>
    <span v-else-if="state === 'ignored'" title="ignored">
        <font-awesome-icon
            icon="fa-solid fa-circle-minus "
            size="sm"
            style="color: #a9b8b1"
        />
    </span>
    <span v-else> - </span>
</template>
<script>
export default {
    name: "TestStatus",
    props: {
        state: undefined,
    },
};
</script>

<style></style>
