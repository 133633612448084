<template>
    <div class="details">
        <div class="spec-name row">
            <span class="fa-layers" style="max-width: 1%; margin-top: 5px">
                <font-awesome-icon
                    icon="fa-solid fa-file fa-stack-1x"
                    size="lg"
                    style="color: rgb(184, 152, 152)"
                />
                <font-awesome-icon
                    icon="fa-solid fa-glasses fa-stack-1x fa-inverse"
                    style="color: whitesmoke"
                    size="2xs"
                />
            </span>
            <a
                :href="specLink"
                target="_blank"
                style="max-width: 95%; font-size: medium"
                >{{ spec.specFileName }}</a
            >
        </div>
        <table class="table test-details">
            <thead>
                <th></th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="(test, testIndex) in getTests()" :key="testIndex">
                    <td style="margin-top: 10px">
                        <TestStatus :state="test.state"></TestStatus>
                    </td>
                    <td class="test-name">
                        <a :href="getTestLink(test)" target="_blank">{{
                            test.testName
                        }}</a>
                    </td>
                    <td class="team">
                        {{ test.team }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TestStatus from "./TestStatus.vue";

export default {
    name: "SpecFileResult",
    components: { TestStatus },
    props: {
        spec: undefined,
        filterToTeam: Boolean,
    },
    data() {
        return {
            code: "",
        };
    },
    computed: {
        tests() {
            return {
                overall: this.spec.testCount,
                passes: this.spec.passedCount,
                failures: this.spec.failedCount,
                skipped: this.spec.skippedCount,
            };
        },
        specLink() {
            return `https://app.currents.dev/instance/${this.spec.instanceId}`;
        },
    },
    methods: {
        getTestLink(test) {
            return `https://app.currents.dev/instance/${this.spec.instanceId}/test/${test.testId}`;
        },
        getTests() {
            if (this.filterToTeam) {
                return this.spec.tests.filter((t) => {
                    return t.team === "Super User";
                });
            }
            return this.spec.tests;
        },
    },
};
</script>

<style>
.details {
    align-content: left;
}
.spec-name {
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 4px;
    display: inline-block;
    max-width: 90%;
}
.test-details {
    margin-left: 1%;
    max-width: 90%;
}

table tr td {
    border-bottom-width: 0px !important;
    background: #fafafa !important;
}

.test-name {
    max-width: 85%;
    width: 100%;
    font-style: italic;
    font-size: small;
}
.team {
    text-align: center;
    font-weight: bold;
    font-size: small;
}
</style>
