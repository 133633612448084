import { createApp } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/toast";
import router from "./router";
import { auth0 } from "./auth0";

/* import specific icons */
import {
    faCodePullRequest,
    faCircle,
    faCircleCheck,
    faCircleMinus,
    faCircleQuestion,
    faMessage,
    faSquareCheck,
    faArrowsRotate,
    faFlaskVial,
    faCircleXmark,
    faForwardStep,
    faGlasses,
    faFile,
    faChevronRight,
    faChevronDown,
    faCodeBranch,
    faCodeCommit,
    faFlask,
    faFilter,
    faCopy,
    faTags,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faCodePullRequest);
library.add(faCircle);
library.add(faCircleCheck);
library.add(faCircleMinus);
library.add(faCircleQuestion);
library.add(faMessage);
library.add(faSquareCheck);
library.add(faArrowsRotate);
library.add(faFlaskVial);
library.add(faCircleXmark);
library.add(faForwardStep);
library.add(faGlasses);
library.add(faFile);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faCodeBranch);
library.add(faCodeCommit);
library.add(faFlask);
library.add(faFilter);
library.add(faCopy);
library.add(faTags);

const app = createApp(App);

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(router).use(auth0).mount("#app");
