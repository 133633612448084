<template>
    <div>
        <header>
            <div class="wrapper">
                <nav>
                    <a v-if="authenticated" href="#" @click="logout">Logout</a>
                </nav>
            </div>
        </header>
        <RouterView />
    </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
    name: "App",
    data() {
        return {
            authenticated: false,
        };
    },
    mounted() {
        const { isAuthenticated } = useAuth0();
        this.authenticated = isAuthenticated;
    },
    methods: {
        logout() {
            this.$auth0.logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            });
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
