<template>
    <div class="card" :class="{ testfail: !pass, testsuccess: pass }">
        <div
            v-if="run"
            id="headingOne"
            class="card-header row face"
            :class="{ closed: !open }"
            scope="row"
            @click="toggleCard()"
        >
            <div class="col chev">
                <span
                    v-if="!open"
                    class="row"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${run.runId}Content`"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-right"
                        title="Click to expand and view test failures"
                    />
                </span>
                <span
                    v-else
                    class="row"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${run.runId}Content`"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-down"
                        title="Click to collapse test failures"
                    />
                </span>
            </div>
            <div class="col title">
                <a
                    class="row run-name"
                    :href="runLink"
                    target="_blank"
                    :title="runTitle"
                    >{{ run.category }}</a
                >
                <RunStatus :passed="pass" :pending="pending"></RunStatus>
                <RunTags
                    :tags="run.tags"
                    :cypress-version="run.cypressVersion"
                ></RunTags>
            </div>
            <span class="col details" style="font-style: italic">
                <div class="row">
                    <div
                        class="col"
                        style="max-width: 4%; margin-top: 2px; margin-left: 5px"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-code-branch"
                            size="sm"
                        />
                    </div>
                    <div class="col" style="margin-left: 2px">
                        {{ run.branch }}
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="max-width: 4%">
                        <font-awesome-icon
                            icon="fa-solid fa-code-commit"
                            size="sm"
                        />
                    </div>
                    <div class="col" style="margin-left: 7px">
                        {{ run.commitMessage }}
                    </div>
                </div>
            </span>
            <CategoryStats
                class="test-stats"
                :tests="run.tests"
                :run-date="runDateTime"
            ></CategoryStats>
        </div>
        <div
            :id="`${run.runId}Content`"
            class="collapse cardopen"
            :class="{ show: open }"
            aria-labelledby="headingOne"
        >
            <SpecsCard :run="run"></SpecsCard>
        </div>
    </div>
</template>
<script>
import CategoryStats from "./CategoryStats.vue";
import RunStatus from "./RunStatus.vue";
import SpecsCard from "./SpecsCard.vue";
import RunTags from "./RunTags.vue";

export default {
    name: "CategoryCard",
    components: { RunStatus, SpecsCard, CategoryStats, RunTags },
    props: {
        run: undefined,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        pass() {
            return this.run.tests.failures == 0;
        },
        pending() {
            return this.run.tests.pending != 0;
        },
        runDateTime() {
            return new Date(this.run.runDateTime).toLocaleString("en-uk", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        },
        runLink() {
            return `https://app.currents.dev/run/${this.run.runId}`;
        },
        runTitle() {
            return `Click to open ${this.run.category} test run in currents`;
        },
    },
    mounted() {},
    methods: {
        toggleCard() {
            this.open = !this.open;
        },
    },
};
</script>

<style>
.card {
    margin-top: 5px;
    margin-bottom: 5px;
}

.closed {
    border-bottom: none !important;
}

.card .face {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    background: white;
    padding-bottom: 10px;
}
.card h2 {
    text-align: left;
}
.title {
    font-size: 1.5rem;
    max-width: 160px !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    display: inline-block;
    white-space: nowrap;
}

@media (max-width: 1225px) {
    .title {
        padding-left: 5% !important;
    }
}

.run-name {
    font-size: medium;
}

.status {
    text-align: left;
    font-size: x-small !important;
    padding-top: 5px;
    max-width: 65% !important;
}
.testfail {
    border-color: rgb(218, 60, 60) !important;
    border-width: 2px !important;
}
.testsuccess {
    border-color: rgb(62, 184, 62) !important;
    border-width: 2px !important;
}
.testpending {
    border-color: #5d61bc !important;
    border-width: 2px !important;
}

.cardopen {
    background: #fafafa;
}

.details {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 13pt;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.test-stats {
    font-size: large;
    max-width: 30% !important;
}

.chev {
    font-size: x-large !important;
    max-width: 3% !important;
    padding-left: 0% !important;
    padding-top: 1% !important;
    padding-right: 3% !important;
}
</style>
