<template>
    <span class="status row">
        <span v-if="anyTags" class="tags" :title="tagsTitle">
            <font-awesome-icon icon="fa-solid fa-tags" />
            <img
                v-if="cypressVersion"
                src="/cypress-logo.png"
                alt="Ewan McGreggor as Obi Wan"
                class="cypress-logo"
            />
            <img
                v-else
                src="/playwright-logo.png"
                alt="Ewan McGreggor as Obi Wan"
                class="playwright-logo"
            />
        </span>
    </span>
</template>
<script>
export default {
    name: "RunTags",
    props: {
        tags: undefined,
        cypressVersion: undefined,
    },
    computed: {
        anyTags() {
            return this.tags.length > 0;
        },
        tagsTitle() {
            return this.tags.join(", ");
        },
    },
};
</script>

<style>
.tags {
    width: 35px !important;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: rgb(71, 71, 71);
    padding-left: 8% !important;
    padding-top: 2% !important;
    color: rgb(173, 170, 165);
}

.playwright-logo {
    width: 18px;
    height: 18px;
    padding-left: 3px;
    padding-bottom: 1px;
}

.cypress-logo {
    width: 15px;
    height: 12px;
    padding-left: 4px;
    padding-bottom: 2px;
}

@media (max-width: 1225px) {
    .tags {
        width: 5px;
        border-radius: 3px;
        max-width: 100% !important;
    }
    .word {
        display: none;
    }
}
</style>
