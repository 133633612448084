<template>
    <div class="container">
        <span v-if="reviewStatus.reviewing && !reviewStatus.status">
            <font-awesome-icon
                icon="fa-solid fa-circle"
                size="sm"
                style="color: #b5b7ba"
                title="no review yet"
            />
        </span>
        <span
            v-else-if="
                reviewStatus.reviewing && reviewStatus.status === 'approved'
            "
        >
            <font-awesome-icon
                icon="fa-solid fa-circle-check"
                size="sm"
                style="color: #36b37e"
                title="approved"
            />
        </span>
        <span
            v-else-if="
                reviewStatus.reviewing &&
                reviewStatus.status === 'changes_requested'
            "
        >
            <font-awesome-icon
                icon="fa-solid fa-circle-minus"
                size="sm"
                style="color: #ffab00"
                title="changes requested"
            />
        </span>
        <span v-else> - </span>
    </div>
</template>
<script>
export default {
    name: "ReviewStatus",
    props: {
        pr: undefined,
        teamMember: undefined,
    },
    data() {
        return {
            reviewStatus: { reviewing: false, status: "" },
        };
    },
    mounted() {
        this.setReviewStatus();
    },
    methods: {
        setReviewStatus() {
            this.reviewStatus = this.getReviewer(
                this.teamMember,
                this.pr.reviewers
            );
        },
        getReviewer(user, reviewers) {
            let reviewer = reviewers.filter(
                (e) => e.uuid === user.bitbucketUuid
            );

            if (reviewer.length > 0) {
                return { reviewing: true, status: reviewer[0].reviewStatus };
            }
            return { reviewing: false, status: "" };
        },
    },
};
</script>

<style>
.avatar {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}
</style>
