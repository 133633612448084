<template>
    <img
        :src="teamMember.avatar"
        :title="teamMember.displayName"
        class="avatar"
    />
</template>
<script>
export default {
    name: "AvatarIcon",
    components: {},
    props: {
        teamMember: {},
    },
};
</script>

<style>
.avatar {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}
</style>
