import RequestsService from "./RequestsService.js";
import UserService from "./UserService.js";

async function getTeam() {
    var user = await UserService.getUser();
    var email = user.value.email;
    return await RequestsService.get(`team/${email}`);
}

export default {
    getTeam: getTeam,
};
