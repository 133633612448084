<template>
    <span class="status row">
        <span v-if="passed" class="dot success" title="test run passed">
            <span class="word">passed</span>
        </span>
        <span v-else-if="!passed" class="dot danger">
            <span class="word">failed</span>
        </span>
    </span>
</template>
<script>
export default {
    name: "RunStatus",
    props: {
        passed: undefined,
        pending: undefined,
    },
};
</script>

<style>
.dot {
    width: 60px !important;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    color: white;
    max-width: none;
}
.danger {
    background-color: rgb(218, 60, 60);
}
.success {
    background-color: #36b37e;
}
.pending {
    background-color: #5d61bc;
}

@media (max-width: 1225px) {
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 3px;
        max-width: 100% !important;
        display: inline-block;
    }
    .word {
        display: none;
    }
}
</style>
