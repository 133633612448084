<template>
    <tr v-if="pr" scope="row">
        <td><AvatarIcon :team-member="owner"></AvatarIcon></td>
        <td style="text-align: left; padding-left: 3px">
            <a :href="URL" target="_blank">{{ pr.title }}</a>
        </td>
        <td v-for="(potentialReviewer, reviewIndex) in team" :key="reviewIndex">
            <ReviewStatus
                :pr="pr"
                :team-member="potentialReviewer"
            ></ReviewStatus>
        </td>
        <td>
            <span
                class="fa-layers fa-fw"
                :title="`${pr.commentCount} total comments`"
            >
                <font-awesome-icon
                    icon="fa-solid fa-message"
                    size="sm"
                    style="color: #4c6794"
                />
                <span
                    v-if="pr.commentCount > 0"
                    class="fa-layers-counter"
                    style="background: Tomato"
                    >{{ pr.commentCount }}</span
                >
            </span>
        </td>
        <td>
            <span class="fa-layers fa-fw" :title="`${pr.openTasks} open tasks`">
                <font-awesome-icon
                    icon="fa-solid fa-square-check"
                    style="color: #4c6794"
                />
                <span
                    v-if="pr.openTasks > 0"
                    class="fa-layers-counter"
                    style="background: Tomato"
                    >{{ pr.openTasks }}</span
                >
            </span>
        </td>
    </tr>
</template>
<script>
import ReviewStatus from "./ReviewStatus.vue";
import AvatarIcon from "../AvatarIcon.vue";

export default {
    name: "PullRequestRow",
    components: { ReviewStatus, AvatarIcon },
    props: {
        pr: {},
        team: {},
    },
    computed: {
        owner() {
            if (this.pr) {
                const owner = this.team.filter(
                    (tm) => tm.bitbucketUuid === this.pr.authorUuid
                );
                if (owner.length > 0) {
                    return owner[0];
                }
            }
            return {};
        },
        URL() {
            if (this.pr) {
                return `https://bitbucket.org/airtsltd/dot/pull-requests/${this.pr.id}`;
            }
            return "";
        },
    },
};
</script>

<style>
.avatar {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
</style>
