<template>
    <div class="container">
        <div class="hello">
            <img
                src="/image.png"
                alt="an abstract watercolour and ink drawing of a person running"
                class="icon"
            />
            ahoy {{ this.userName }}
        </div>
        <div class="widget">
            <PRs :team="team"></PRs>
        </div>
        <div class="widget">
            <TestRuns> </TestRuns>
        </div>
    </div>
</template>
<script>
import TeamService from "@/services/TeamService";
import UserService from "@/services/UserService";
import PRs from "../components/pullrequests/PRs.vue";
import TestRuns from "../components/tests/TestRuns.vue";

export default {
    name: "HomePage",
    components: { PRs, TestRuns },
    data() {
        return {
            team: [],
            teamsLoaded: false,
            userName: "",
        };
    },
    mounted() {
        this.loadTeams();
        UserService.getUserNiceName().then((userNiceName) => {
            this.userName = userNiceName;
        });
    },
    methods: {
        loadTeams() {
            TeamService.getTeam()
                .then((res) => {
                    this.team = res.data;
                    this.teamsLoaded = true;
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.error(error);
                });
        },
    },
};
</script>

<style>
.hello {
    text-align: left;
    padding-bottom: 40px;
    font-style: italic;
    color: grey;
}
.widget {
    border: solid;
    border-color: #dee2e6;
    border-width: 1px;
    margin-bottom: 40px;
}
.icon {
    margin-bottom: 15px;
    margin-right: 3px;
    height: 30px;
    width: 30px;
}
</style>
